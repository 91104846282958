//Franchise
import React from "react";
import Franchisefrom from "../components/modules/Franchiseform";
import Franchisepage from "../components/modules/Franchisepage";
const Franchise = () => {
  return (
    <div>
      <Franchisepage/>
      <Franchisefrom/>
    </div>
  );
};

export default Franchise;
