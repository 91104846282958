import React from "react";
import ProductHighlight from "../components/modules/ProductHighlight";
import ProductHighlightData from "../components/data/ProductHighlightData";
import VideoBlock from "../components/modules/VideoBlock";
import OnlyImages from "../components/modules/OnlyImages";
import Herocarosel from "../components/modules/Herocarosel";
import DeliveryModule from "../components/modules/DeliveryModule";
import Seo from "../components/modules/Seo";
import Review from "../components/modules/Review";
import Ragimodule from "../components/modules/Ragimodule";
const objSeo = {
  title: "Bite Byte Foods - Home of All-Natural Idly Dosa Batter",
  keywords:
    " Bite Byte Foods, Idly Dosa batter, ready to cook, no preservatives, all-natural, free delivery, Bagalkot region, customer convenience, no soda, best idly and dosa in bagalkot",
  pathSlug: "/",
  baseUrl: "https://bitbytefoods.com",
  description:
    "Welcome to Bite Byte Foods, your trusted source for all-natural and preservative-free Idly Dosa batter. Our products are ready to cook and made with the utmost care to ensure you enjoy authentic South Indian flavors. Join us in the celebration of convenient and delicious South Indian cuisine.",
};
const Home = () => {
  return (
    <div>
      <Herocarosel />

      <Seo data={objSeo} />
      <OnlyImages />
      <ProductHighlight products={ProductHighlightData} />
      <VideoBlock />
      <Ragimodule/>
      <DeliveryModule />
      <Review />
    </div>
  );
};

export default Home;
