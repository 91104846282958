const ProductHighlightData = [
  {
    id: 1,
    imageUrl1: "/images/riceraw2.jpg",
    imageUrl2: "/images/urad2.jpg",
    text: "All our ingredients and raw materials are sourced locally, and we use only RO water to ensure that all our products have a great and refreshing taste.",
    imageOnLeft: true,
    stack: "image-on-left",
    leftGrid: 6,
    rightGrid: 6,
    productriceurad: "product-rice-urad",
  },
  {
    id: 2,
    imageUrl: "/images/wetgrinder.png",
    text: "Our grinders are traditional single stone grinders. These take time, and we believe that good food takes time, just as the most beautiful flowers bloom in their own sweet time.",
    imageOnLeft: false,
    stack: "image-on-right",
    leftGrid: 6,
    rightGrid: 6,
    productwetgrinder: "product-wetgrinder",
  },
  {
    id: 3,
    imageUrl: "/images/packmin.png",
    text: "Our idly and dosa batter packets are sealed in food-grade quality materials, featuring a convenient ziplock to preserve freshness for an extended period.",
    imageOnLeft: true,
    stack: "image-on-left",
    leftGrid: 6,
    rightGrid: 6,
    productpacket: "idli-dosa-packet",
    idlidosatext: "idli-dosa-text",
    idlidosaimage: "idli-dosa-image",
  },
];

export default ProductHighlightData;
