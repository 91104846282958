import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../style/Whatsapp.css";

const Whatsapp = (props) => {
  return (
    <div className="iconcontainer">
      <div className="icon-bar">
        <a href="https://api.whatsapp.com/send?phone=916363018056">
          <span style={{ fontSize: "40px" }}>
            <FaWhatsapp className="whatsappicon green-color" />
          </span>
        </a>
      </div>
    </div>
  );
};
export default Whatsapp;
