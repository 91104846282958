import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import "../style/ThreeColContactus.css";
import Contactform from "./ContactForm";
import { TelephoneForward, At, GeoAlt } from "react-bootstrap-icons";

const Contactus = () => {
  return (
    <Container md={3} className="contactus-container">
      <Row>
        <Col md={4}>
          <p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3831.952523510548!2d75.65902471477932!3d16.17139848881794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc7797d049a228d%3A0x39bb416c679f0b32!2sBB's%20Idly%20Dosa%20Camp!5e0!3m2!1sen!2sin!4v1679032424386!5m2!1sen!2sin"
              className="school-location"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </p>
        </Col>
        <Col md={4}>
          <h2 className="sepl-primary-dark">Meet Us </h2>
          <p>
            {/* <i
              class="bi bi-TelephoneForward d-inline "
              style={{ color: "#011c02" }}
            ></i> */}
            <TelephoneForward color="royalblue" size={15} />
            +91 6363018056
          </p>
          <p>
            {/* <i class="bi bi-at" style={{ color: "#011c02" }}></i> */}
            <At color="royalblue" size={15} />
            bitbytefoods@gmail.com
          </p>
          <p>
            {/* <i class="bi bi-geo-alt" style={{ color: "#011c02" }}></i> */}
            <GeoAlt color="royalblue" size={15} />
            BB's Idly Dosa Camp Shop 2, Jain mandir complex Vidayagiri,
            Bagalkote, Karnataka 587102
          </p>
        </Col>
        <Col md={4}>
          {" "}
          <h2>Contact Us</h2>
          <Contactform></Contactform>
        </Col>
      </Row>
    </Container>
  );
};

export default Contactus;
