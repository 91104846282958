//Franchisefrom
import React, { useState } from 'react';

const Franchisefrom = () => {
    const [form, setForm] = useState({
        name: '',
        contactNo: '',
        emailId: '',
        whatsapp: '',
        state: '',
        district: '',
        city: '',
        shopSpaceReady: '',
        investment: '',
        start: '',
      });
    
      const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', form);
      };
    
      return (
            <div className="row-container">
              <div className="image-container">
                <img src="images/franchise1.jpg" alt="Image" className="form-image" />
                <img src="images/franchise2.jpg" alt="Franchise image" className="franchise-image"/>
              </div>
              <form action="https://formbold.com/s/3NvEz" method="post" className="form-container"> 
          <label className="form-label">
            <input className="input-field " type="text" name="Name" value={form.Name} onChange={handleChange} placeholder="Name" />
          </label>
          <label className="form-label">
            <input className="input-field" type="text" name="phone_number" value={form.phone_number} onChange={handleChange} placeholder="Contact No." />
          </label>
          <label className="form-label">
            <input className="input-field" type="email" name="email" value={form.email} onChange={handleChange} placeholder="Email id" />
          </label>
          <label className="form-label">
            <input className="input-field" type="text" name="whatsapp" value={form.whatsapp} onChange={handleChange} placeholder="Whatsapp No." />
          </label>
          <label className="form-label">
            <input className="input-field" type="text" name="state" value={form.state} onChange={handleChange} placeholder="State" />
          </label>
          <label className="form-label">
            <input className="input-field" type="text" name="district" value={form.district} onChange={handleChange} placeholder="District" />
          </label>
          <label className="form-label">
            <input className="input-field" type="text" name="city" value={form.city} onChange={handleChange} placeholder="City" />
          </label >
          <label className="form-label">
            <span><b>Do you have a shop space ready?</b></span>
            <select className="input-field" name="ShopSpaceReady" value={form.ShopSpaceReady} onChange={handleChange}>
              <option value="select">Select..</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <label className="form-label">
            <span><b>Investment Capacity?</b></span>
            <select className="input-field" name="Investment" value={form.Investment} onChange={handleChange}>
              <option value="">Select..</option>
              <option value="5 Lakh">5 Lakh</option>
              <option value="10 Lakh">10 Lakh</option>
            </select>
          </label>
          <label className="form-label">
            <span><b>By when you want to start?</b></span>
            <select className="input-field" name="start" value={form.start} onChange={handleChange}>
              <option value="">Select..</option>
              <option value="Less than 1 month"> Less than 1 month</option>
              <option value="1 month">1 month</option>
              <option value="2 month">2 month</option>
            </select>
          </label>
          <button type="apply" class="franchise-apply-button">Apply</button>
          </form>
        </div>
       
      );
};

export default Franchisefrom;