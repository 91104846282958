import React from "react";
import "../style/Heropage.css";

const Heropage = () => {
  return (
    <div className="hero-container">
      <div className="textside">
        <div className="mobile-only-text">
          <h1 className="text-center">
            <span className="highlight">BIT BYTE FOODS</span>{" "}
          </h1>

          <p className="para">
            A Meticulous Journey to Pure Delight. The True Taste of Tradition!
          </p>
        </div>

        <div className="sketch1 sketch11">
          <img src="images/sketchp-min.png" alt="" />
        </div>

        <div className="sketch2 sketch11">
          <img src="images/sketch2-min.png" alt="" />
        </div>
        <div className="sketch3 sketch11">
          <img src="images/sketch3-min.png" alt="" />
        </div>
      </div>
      <div className="imageside">
        <img className="aa" src="images/resized.png" alt="" />
        <div className="imageside-mobile text-center">
          <img className="mobile-aa" src="images/resized.png" alt="" />
        </div>
        <div className="packet">
          <div className="pck">
            <img src="images/packmin.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Heropage;
