import "./App.css";
import Faq from "./views/Faq";
import Gallery from "./views/Gallery";
import Camp from "./views/Camp";
import Home from "./views/Home";
import Navigation from "./components/modules/Navigation";
import Footer from "./components/modules/Footer";
import Franchise from './views/Franchise';
import { Routes, Route } from "react-router-dom";
import LoyaltyProgram from "./views/LoyaltyProgram";
import ContactUs from "./views/ContactUs";
import Whatsapp from "./components/modules/Whatsapp";
import Cookies from "./components/modules/Cookies";
import PrivacyPolicy from "./views/PrivacyPolicy";
import QuickLinks from "./components/modules/QuickLinks";

const App = () => {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/faq" element={<Faq />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/idlydosacamp" element={<Camp />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/" element={<Home />} />
        <Route path="/rewardsprogram" element={<LoyaltyProgram />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
      <QuickLinks />
      <Whatsapp />
      <Cookies />
      <Footer />
    </div>
  );
};

export default App;
