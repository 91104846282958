import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import "../style/QuickLinks.css";
const QuickLinks = () => {
  return (
    <div className="social-media p-0">
      <a
        href="https://www.facebook.com/bitbytefoods"
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <FaFacebook />
      </a>
      <a
        href="https://www.youtube.com/@BitByte-Batter"
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <FaYoutube />
      </a>
      <a
        href="https://www.instagram.com/bitbytefoods/"
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.linkedin.com/company/bit-byte-foods/"
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <FaLinkedinIn />
      </a>
    </div>
  );
};

export default QuickLinks;
