// import "../style/Gallery.css";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import "../style/Gallery.css";
const images = [
  { type: "image", src: "images/gallery/img1.jpeg", alt: "Image 1" },
  { type: "image", src: "images/gallery/img2.jpeg", alt: "Image 2" },
  { type: "image", src: "images/gallery/img3.jpeg", alt: "Image 3" },
  { type: "image", src: "images/gallery/bbcamp.jpg", alt: "Image 4" },
  { type: "image", src: "images/gallery/pic1.jpg", alt: "Image 5" },
  { type: "image", src: "images/gallery/pic2.jpg", alt: "Image 6" },
  { type: "image", src: "images/gallery/pic3.jpg", alt: "Image 7" },
  { type: "image", src: "images/gallery/pic4.jpg", alt: "Image 8" },
  { type: "image", src: "images/gallery/pic5.jpg", alt: "Image 9" },
  { type: "image", src: "images/gallery/bbcamp1.JPG", alt: "Image 10" },
  { type: "image", src: "images/gallery/bbcamp2.JPG", alt: "Image 11" },
  { type: "image", src: "images/gallery/bbcamp4.jpg", alt: "Image 12" },
  { type: "image", src: "images/gallery/bbcamp5.jpg", alt: "Image 13" },
  { type: "image", src: "images/gallery/bbcamp6.jpg", alt: "Image 14" },
  { type: "image", src: "images/gallery/pic7.png", alt: "Image 15" },
  { type: "image", src: "images/gallery/pic8.jpg", alt: "Image 16" },
  { type: "image", src: "images/gallery/pic9.jpg", alt: "Image 17" },
  { type: "image", src: "images/gallery/pic10.jpg", alt: "Image 18" },
  { type: "image", src: "images/gallery/pic11.jpeg", alt: "Image 19" },
  { type: "image", src: "images/gallery/pic12.jpeg", alt: "Image 20" },
  { type: "image", src: "images/gallery/pic13.jpg", alt: "Image 21" },
  { type: "image", src: "images/gallery/pic14.jpg", alt: "Image 22" },
  { type: "image", src: "images/gallery/pic15.jpg", alt: "Image 23" },
  { type: "image", src: "images/gallery/pic16.jpg", alt: "Image 24" },
  { type: "image", src: "images/gallery/pic17.jpg", alt: "Image 25" },
  { type: "image", src: "images/gallery/pic18.jpg", alt: "Image 26" },
  { type: "image", src: "images/gallery/pic19.jpg", alt: "Image 27" },
  { type: "image", src: "images/gallery/pic20.jpg", alt: "Image 28" },
];
const videos = [
  { type: "video", src: "videos/BitByteGiving.mp4", alt: "Video 1" },
  { type: "video", src: "videos/bbfvideo.mp4", alt: "Video 2" },
  { type: "video", src: "videos/videoautoplay.MP4", alt: "Video 3" },
  { type: "video", src: "videos/BBCamp.mp4", alt: "Video4" },
];

function BbfGallery() {
  const [fullscreenItem, setFullscreenItem] = useState(null);

  const openFullscreen = (index) => {
    setFullscreenItem(index);
  };

  const closeFullscreen = () => {
    setFullscreenItem(null);
  };

  const renderFullscreenItem = (index) => {
    const item = images.concat(videos)[index];

    if (!item) return null;

    if (item.type === "image") {
      return (
        <img src={item.src} alt={item.alt} className="Bbfgallery__item-image" />
      );
    }

    if (item.type === "video") {
      return (
        <ReactPlayer
          url={item.src}
          playing={fullscreenItem === index}
          controls
          width="100%"
          height="100%"
        />
      );
    }

    return null;
  };

  return (
    <div className="Bbfgallery">
      {images.map((item, index) => (
        <div
          key={index}
          className="Bbfgallery__item"
          onClick={() => openFullscreen(index)}
        >
          <img
            src={item.src}
            alt={item.alt}
            className="Bbfgallery__item-image"
          />
        </div>
      ))}
      {videos.map((item, index) => (
        <div
          key={index + images.length} // Ensure unique keys
          className="Bbfgallery__item"
          onClick={() => openFullscreen(index + images.length)}
        >
          <div className="Bbfgallery__item-video">
            <ReactPlayer
              url={item.src}
              playing={fullscreenItem === index + images.length}
              controls
              width="100%"
              height="100%"
            />
          </div>
        </div>
      ))}
      {fullscreenItem !== null && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <div className="fullscreen-item-container">
            {renderFullscreenItem(fullscreenItem)}
          </div>
        </div>
      )}
    </div>
  );
}

export default BbfGallery;
