import Accordion from "react-bootstrap/Accordion";
import "../components/style/Faq.css";
import Seo from "../components/modules/Seo";
import { Container } from "react-bootstrap";
import RewardsFaq from "../components/modules/RewardsFaq";
const objSeo = {
  title: "FAQ: Idly Dosa Batter Preparation and Product Authenticity",
  keywords:
    " Idly Dosa batter, preparation process, product authenticity, finest quality ingredients, locally sourced, fresh products, authentic taste,  FAQ, frequently asked questions, Bagalkot, Karnataka",
  pathSlug: "/faq",
  baseUrl: "https://bitbytefoods.com",
  description:
    "Welcome to our FAQ page, where we address common questions about our products and our commitment to maintaining product authenticity.",
};
const Faq = () => {
  return (
    <>
      <Container>
        <div className="text-center">
          <video loop muted autoplay="autoplay" className="faq-video ">
            <source src="videos/bbfvideo.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="bbfreact-faq">
          <Seo data={objSeo} />
          <h1 style={{ textAlign: "center" }}>General FAQ's</h1>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                What makes your idly and dosa batter special?
              </Accordion.Header>
              <Accordion.Body>
                We take pride in using only natural ingredients and avoiding
                preservatives in our batter. Our idly and dosa batter is made
                with care and attention to quality, ensuring a delicious and
                healthy option for our customers.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                Where can I get these products?
              </Accordion.Header>
              <Accordion.Body>
                We are currently located in <strong>Bagalkot</strong>, offering
                our idly and dosa batter to customers in the local area.
                However, we have plans to expand to nearby places in the near
                future.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                Do you add soda to your batter?
              </Accordion.Header>
              <Accordion.Body>
                No,
                <strong>we do not add soda to our idly and dosa batter.</strong>
                Our batter is made using traditional methods, without the use of
                any artificial additives or soda.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                Is the batter used in your restaurant made in-house?
              </Accordion.Header>
              <Accordion.Body>
                Yes, the batter used in our restaurant is made in-house using
                our own specially crafted recipe. We take great care in
                preparing our batter to ensure the best taste and quality for
                our customers.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {" "}
                Are there any preservatives in your batter?
              </Accordion.Header>
              <Accordion.Body>
                No, we do not use any preservatives in our idly and dosa batter.
                We believe in providing our customers with a natural and healthy
                option for their meals.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                How do you ensure the freshness of your batter?
              </Accordion.Header>
              <Accordion.Body>
                We take great care in preparing our batter fresh each day to
                ensure its quality and freshness. Our batter is made in small
                batches, and we do not use any preservatives or additives,
                ensuring a natural and fresh product.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
      <RewardsFaq />
    </>
  );
};

export default Faq;
