import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/Livekitchen.css";

const Livekitchen = () => {
  return (
    <div>
      <Container className="Livekitchen-container">
        <h1 className="text-center">
          Experience the amazing taste with our{" "}
          <span className="highlight">Live Kitchen Service!</span>
        </h1>
        <p className="text-center">
          <h6>
            Craving hot and delicious idly, vada, and dosa for your small to
            medium to large parties? Look no further!
          </h6>
        </p>
        <h6 className="text-center">
          <span className="highlight"> BB Idly Dosa camp’s </span> live kitchen
          service is here to bring the goodness right to your doorstep.
        </h6>

        {/* Two Columns */}
        <Row className="Livekitchen-row">
          {/* First Column for Text */}
          <Col lg={6} style={{ textAlign: "justify" }}>
            <h5>✨ What Sets Us Apart?</h5>
            <p>
              Immerse your guests in the irresistible aroma and savor the
              freshness of each dish prepared right before their eyes. 🏠
              Convenience at <span className="highlight">Your Doorstep</span>{" "}
              hosting an event has never been this easy! With our Live Kitchen
              Service, enjoy the convenience of having a professional kitchen
              at your venue.
            </p>

            <h5>🎊 Perfect for Any Occasion</h5>
            <p>
              From intimate family gatherings to corporate events, our Live
              Kitchen Service adds a touch of excitement to every occasion.
              Impress your guests with the interactive and delightful
              experience of live cooking.
            </p>
          </Col>

          {/* Second Column for Image */}
          <Col lg={6} className="Livekitchenimage-col">
            <img
              src="images/livekitchen.jpg"
              style={{
                width: "100%",
              }}
              className="Livekitchenimage"
              alt="Live Kitchen"
            />
          </Col>
          
          {/* Contact Details Section Below Image */}
          <Col lg={12} className="text-center">
            <h5>
              📞 Book Our{" "}
              <span className="highlight">Live Kitchen Today!</span>
               Call us @ 6363018056
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Livekitchen;


