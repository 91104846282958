import React from "react";
import VideoText from "../components/modules/VideoText";
import ThreeCrossTwo from "../components/modules/ThreeCrossTwo";
import Herocamp from "../components/modules/Herocamp";
import Menu from "../components/modules/Menu";
import Seo from "../components/modules/Seo";
import Livekitchen from "../components/modules/Livekitchen";
const objSeo = {
  title: "Idly Dosa Camp - Authentic South Indian Cuisine in Bagalkot",
  keywords:
    " Idly Dosa Camp, South Indian cuisine, authentic, Bagalkot, menu, delicious dishes, specialty, idly, dosa, classic, cravings",
  pathSlug: "/camp",
  baseUrl: "https://bitbytefoods.com",
  description:
    "Welcome to IDLY DOSA CAMP, your premier destination for authentic South Indian cuisine located in Shop No.2, Jain Mandir Complex, Vidyagiri-Bagalkot. Our menu offers a wide variety of delicious dishes, but our specialty lies in the classic South Indian favorites - idly and dosa.",
};
const Camp = () => {
  return (
    <div>
      <Seo data={objSeo} />
      <Herocamp />
      {/* <VideoText /> */}
      <ThreeCrossTwo />
      <Livekitchen/>
      <Menu />
    </div>
  );
};

export default Camp;
