import React from "react";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
const RewardsFaq = () => {
  return (
    <>
      {" "}
      <Container>
        <div className="bbfreact-faq">
          <h1 style={{ textAlign: "center" }}>Rewards Program FAQ's</h1>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How do I enroll in the Bit Byte Rewards Program?
              </Accordion.Header>
              <Accordion.Body>
                Enrolling in our Rewards Program is simple! Just visit our store
                and let our staff know that you'd like to join. We'll provide
                you with a rewards card, and you can start collecting points
                with your next idly or dosa batter purchase.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                Can I use my rewards points or rewards for other products in the
                store?
              </Accordion.Header>
              <Accordion.Body>
                Currently, our Rewards Program is specific to idly and dosa
                batter purchases. The rewards you earn can be redeemed for a
                free idly or dosa batter of your choice.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                How long do I have to redeem my free idly or dosa batter?
              </Accordion.Header>
              <Accordion.Body>
                You have two months from the date of your first purchase to
                redeem your free idly or dosa batter. After this period, the
                rewards will expire, so make sure to enjoy your reward while
                it's fresh and delicious!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                Can I combine my rewards points with those of a family member or
                friend to earn a free batter more quickly?
              </Accordion.Header>
              <Accordion.Body>
                Rewards points are non-transferable and specific to the
                individual who enrolled in the program. Each rewards card is for
                personal use only, and rewards are earned based on individual
                purchases.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                What happens if I lose my loyalty card with collected stamps?
              </Accordion.Header>
              <Accordion.Body>
                We recommend taking good care of your loyalty card. If it's lost
                or damaged, please visit our store, one of our staff will assist
                you. We encourage our customers to keep their cards in a safe
                place to ensure you enjoy the rewards you've earned.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
      ;
    </>
  );
};

export default RewardsFaq;
