import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/DeliveryModule.css";
const DeliveryModule = () => {
  return (
    <div>
      <Container className="deliverymodule-container ">
        <h1 className="text-center">
          Introducing{" "}
          <span className="highlight">Hassle-Free Home Delivery </span>
          in Bagalkot
        </h1>
        <h5 className="text-center">
          Craving the <span className="highlight">authentic taste </span>of
          idlis and dosas, but don't want to step out? We've got you covered!
        </h5>
        <Row className="delivery-row">
          <Col lg={6} style={{ textAlign: "justify" }}>
            Now, enjoy the convenience of free home delivery in and around
            Bagalkot. Our scheduled delivery service ensures that your favorite
            batter arrives at your doorstep within just a few hours. No need to
            leave the comfort of your home, and there's no minimum purchase
            required. We're happy to deliver even if it's just one pack –
            because your satisfaction is our top priority. Order now and savor
            the goodness of our premium batter in the heart of your home.
          </Col>
          <Col lg={6} className="deliveryimage-col">
            <img
              src="images/deliverypic.png"
              style={{
                width: "75%",
              }}
              className="deliveryimage"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryModule;
