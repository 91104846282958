import React from "react";
import ThreeColContactUs from "../components/modules/ThreeColContactus";
import Seo from "../components/modules/Seo";
const objSeo = {
  title: "Contact Us for Idly Dosa Batter Inquiries",
  keywords:
    "Contact us, Idly Dosa batter, inquiries, customer support, authenticity, fresh products, finest quality ingredients, local sourcing, product questions, customer service, Bagalkot, Karnataka",
  pathSlug: "/contactus",
  baseUrl: "https://bitbytefoods.com",
  description:
    "Feel free to get in touch with us regarding any inquiries, questions, or feedback about our Idly Dosa batter. We're here to assist you with information on our meticulous preparation process, ingredient quality, and product authenticity.",
};
function ContactUs() {
  return (
    <div>
      <Seo data={objSeo} />
      <ThreeColContactUs />
    </div>
  );
}

export default ContactUs;
