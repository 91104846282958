import React from "react";
import "../style/Herocamp.css";

function Herocamp() {
  return (
    <div>
      <div className="hero-video-container">
        <video
          className="hero-video"
          autoPlay
          muted
          loop
          type="video/mp4"
          src="images/bbfcampvideo.mp4"
        ></video>

        <h2 className="herocamptitle">
          Welcome to <span className="highlight">IDLY DOSA CAMP</span>, the
          premier destination for authentic South Indian cuisine in Bagalkot
        </h2>
      </div>
    </div>
  );
}
export default Herocamp;
