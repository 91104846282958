import React from "react";
import "../style/ProductHighlight.css";
import { Container, Row, Col } from "react-bootstrap";

const ProductHighlight = (props) => {
  const { products } = props;

  return (
    <>
      <Container className="alternatesection  text-center pt-3">
        <Row>
          <Col lg={12}>
            <h1>
              The Art of Crafting the{" "}
              <span className="highlight">Perfect Batter</span>
            </h1>

            <p>
              {" "}
              Bringing you the taste of home with our{" "}
              <span className="highlight"> authentic batter</span> for crispy
              dosas and fluffy idlis, just like mom's.
            </p>
          </Col>
        </Row>
        {products.map((e) => {
          return (
            <Row key={e.id} className={e.stack}>
              {e.id === 1 ? (
                <>
                  <Col
                    lg={e.leftGrid}
                    className={`left-img rice-urad ${e.productriceurad}`}
                    style={{ backgroundColor: `${e.color} ` }}
                  >
                    <img
                      src={e.imageUrl1}
                      alt="Flings, chips, namkeens"
                      style={{ margin: "auto" }}
                    />
                    <img
                      src={e.imageUrl2}
                      alt="Flings, chips, namkeens"
                      style={{ margin: "auto" }}
                    />
                  </Col>
                  <Col lg={e.rightGrid} className="content">
                    <p>{e.text}</p>
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    lg={e.leftGrid}
                    className="left-img"
                    style={{ backgroundColor: `${e.color} ` }}
                  >
                    <img
                      src={e.imageUrl}
                      alt="Flings, chips, namkeens"
                      className={`  ${e.productwetgrinder} ${e.productpacket} ${e.idlidosaimage} `}
                    />
                  </Col>

                  <Col lg={e.rightGrid} className="content ">
                    <p className={e.idlidosatext}>{e.text}</p>
                  </Col>
                </>
              )}
            </Row>
          );
        })}
      </Container>

      <Container>
        <Row>
          <Col lg={6} className=" spacing-image text-center">
            <img
              src="images/idc.png"
              style={{ width: "70%", height: "100%" }}
            />
          </Col>
          <Col
            lg={6}
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "justify",
            }}
          >
            <p className=" spacing-text">
              {" "}
              If you still remain unconvinced, we encourage you to visit our{" "}
              <span className="highlight">
                <a
                  href="/idlydosacamp"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Idly Dosa Camp
                </a>
              </span>{" "}
              (QSR or Darshini), where we use our own batter. This allows
              customers to first try our product before making a purchase.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductHighlight;
