import React from "react";
import "../style/Franchise.css";

const Franchisepage = () => {
  return (
    <div className="container">
      {/* First Row: Image */}
      <div className="image-container">
        <img src="images/franchise.jpg" alt="User" className="user-image" />
      </div>

      {/* Second Row: Content */}
      <div className="text-center">
        <h1>
          Welcome to the Flavorful World of{" "}
          <span className="highlight">
            {" "}
            Idly Dosa Camp Franchise Opportunity!{" "}
          </span>
        </h1>
        <div class="container mx-3">
          <p>
            Calling all entrepreneurs with a passion for authentic South Indian
            cuisine! Idly Dosa Camp, is offering exciting franchise
            opportunities to bring the taste of tradition to your city in and
            around North Karnataka.
          </p>
        </div>
      </div>
      <div className="text-center">
        <h2>
          Why Choose Idly Dosa Camp?<span className="highlight"></span>
        </h2>
      </div>
      <div className="franchise-text-justify">
        <ul class="mb-3 mx-5 ps-4">
          <li>
            <b> Authenticity Redefined: </b>
            At Idly Dosa Camp, we are committed to preserving the authenticity
            of traditional recipes, delivering an unparalleled taste experience
            to our customers.
          </li>
          <li>
            <b>Unique Business Model: </b>
            Our proven and streamlined business model ensures a smooth operation
            for franchisees. From sourcing high-quality ingredients to efficient
            kitchen processes, we've got it all figured out, making it easy for
            you to focus on delighting customers.
          </li>
          <li>
            <b>Comprehensive Training: </b>
            Don't worry if you're not a culinary expert! Our comprehensive
            training program covers everything from menu preparation to customer
            service, equipping you with the skills needed to run a successful
            Idly Dosa Camp franchise.
          </li>
          <li>
            <b>Premium Ingredients, Premium Quality: </b>
            We believe in using only the{" "}
            <span style={{ display: "inline-block" }} className="highlight">
              {" "}
              finest, freshest ingredients{" "}
            </span>{" "}
            to create our mouthwatering dishes, with a special emphasis on
            incorporating the unique flavors of South India. Joining us means
            becoming part of a brand that prioritizes quality in every aspect of
            its operation.
          </li>
        </ul>

        <div className="text-center left">
          <h2>Steps to Become an Idly Dosa Camp Franchisee</h2>
        </div>
        <div className="franchise-text-justify">
          <ol class="mb-3 mx-5 ps-4">
            <li>
              <b>Submit Your Application: </b>
              Start your journey by filling out our franchise application form.
              Tell us about your passion for South Indian cuisine and your
              vision for your Idly Dosa Camp.
            </li>
            <li>
              <b>Initial Consultation: </b>
              Once we receive your application, we'll schedule an initial
              consultation to discuss the franchise opportunity in more detail.
              This is your chance to ask questions and learn more about what it
              takes to be a successful Idly Dosa Camp franchisee.
            </li>
            <li>
              <b> Location Assessment: </b>
              Work with our team to identify the perfect location for your Idly
              Dosa Camp. We'll provide guidance on site selection to ensure
              optimal visibility and foot traffic. Given that we offer
              all-natural batter without any preservatives, our franchise
              opportunity is specifically tailored for entrepreneurs in{" "}
              <span style={{ display: "inline-block" }} className="highlight">
                {" "}
                North Karnataka.
              </span>
            </li>
            <li>
              <b>Training Program: </b>
              Embark on a comprehensive training program designed to prepare you
              for the ins and outs of operating an Idly Dosa Camp. From kitchen
              operations to customer service, we've got you covered.
            </li>
            <li>
              <b>Grand Opening Support: </b>
              Receive support from the Idly Dosa Camp team during your grand
              opening. We'll assist with marketing strategies, promotional
              events, and more to ensure a successful launch.
            </li>
            <li>
              <b>Ongoing Support: </b>
              Enjoy continuous support from the Idly Dosa Camp team. Benefit
              from marketing initiatives, operational guidance, and regular
              updates to keep your franchise thriving.
            </li>
          </ol>
          <div className="text-center">
            <h2>
              <b>Ready to embark on a delicious journey?</b>
            </h2>
          </div>
          <div class="franchise-text-justify">
            <div class="container mx-3">
              <p>
                <span style={{ display: "inline-block" }} className="highlight">
                  {" "}
                  Call us!
                </span>{" "}
                today @ 6363018056 or Fill the form below to learn more about
                franchise opportunities with Idly Dosa Camp. We'll be happy to
                answer your questions and guide you on the path to owning your
                own slice of South Indian paradise!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Franchisepage;
