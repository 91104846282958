import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/OnlyImages.css";
const OnlyImages = () => {
  return (
    <div>
      <Container className="OnlyImages-Container">
        <Row className="onlyimages-row" style={{ margin: "auto" }}>
          <Col lg={4} xs={6} className="onlyimages-images">
            <img
              src="images/freshfood.png"
              alt="freshfood"
              className="onlyimages-freshfood"
            />
          </Col>
          <Col lg={4} xs={6} className="onlyimages-images">
            <img src="images/hygiene.png" alt="hygiene" />
          </Col>
          <Col lg={4} xs={12} className="onlyimages-images">
            <img
              src="images/natural-bg.png"
              alt="natural"
              className="onlyimages-natural"
            />
          </Col>
        </Row>
        <Row className="onlyimages-row" style={{ margin: "auto" }}>
          {" "}
          <Col lg={4} xs={6} className="onlyimages-images">
            <img
              src="images/no-preservatives.png"
              className="onlyimages-nopreservatives"
              alt="no-preservatives"
            />
          </Col>
          <Col lg={4} xs={6} className="onlyimages-images">
            <img src="images/ready_to_cook.png" alt="ready to cook" />
          </Col>
          <Col lg={4} xs={12} className="onlyimages-images">
            <img src="images/finest_ingrident.png" alt="finest ingrident" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OnlyImages;
