import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/Loyalty.css";

const Loyalty = (props) => {
  const { loyalty } = props;

  return (
    <>
      <Container className="Loyalty-container loyalty-program ">
        <Row>
          <Col lg={12} xs={12} className="text-center">
            <img
              src="images/loyalty/rewards.png"
              style={{ width: "80%" }}
              alt="Loyalty Program"
            />
          </Col>
          <Col lg={12} xs={12}>
            <h1 className="text-center">
              Welcome to Our Exclusive
              <span className="highlight">Bit Byte Rewards</span> Program!
            </h1>
            <p>
              At Bit Byte Foods, we value your loyalty and appreciate your
              continued support. We are thrilled to introduce our brand-new
              rewards program designed exclusively for our esteemed customers
              who have a special place in their hearts for delicious idly and
              dosa batter. If you're a fan of these delectable South Indian
              delicacies, you're in for a treat!
            </p>
          </Col>
        </Row>
        <h1 className="text-center loyalty-heading">How It Works:</h1>
        {loyalty.map((e) => (
          <Row key={e.id} className={e.stack}>
            <>
              <Col
                lg={e.leftGrid}
                className={`left-img-loyalty rice-urad ${e.textimageloyal}`}
                style={{ backgroundColor: `${e.color}` }}
              >
                {e.id === 3 ? (
                  // Display video for ID: 3
                  e.videohtml
                ) : (
                  // Display image for other IDs
                  <img
                    src={e.imageUrl}
                    alt="Flings, chips, namkeens"
                    style={{ margin: "auto" }}
                    className={` ${e.productspurchaseexpire} ${e.productloyalty} ${e.productupdate} ${e.productexpire} `}
                  />
                )}
                <p
                  className={`highlight ${e.textimageposition} ${e.textimagestay} ${e.textimageearn}`}
                >
                  <b>{e.textimage}</b>
                </p>
              </Col>
              <Col
                lg={e.rightGrid}
                className={`content ${e.textupdate} ${e.textexpire}`}
              >
                <p>{e.text}</p>
              </Col>
            </>
          </Row>
        ))}
        <Row className="termscondition-row">
          <h1 className="text-center">Terms and Conditions:</h1>
          <Col lg={10} style={{ margin: "auto" }}>
            <ul>
              <li>
                This Loyalty Program is exclusively applicable for purchases
                made at Idly Dosa Camp, Vidyagiri,Bagalkot
              </li>
              <li>To enroll, simply ask our staff during your next visit.</li>
              <li>
                Each point is earned with the purchase of our idly or dosa
                batter.
              </li>
              <li>
                Once you've collected 10 points, you can redeem them for a free
                idly or dosa batter of your choice.
              </li>
              <li>
                Stay connected with us by keeping an eye on your SMS or WhatsApp
                messages for updates and exclusive offers.
              </li>
              <li>You need to earn these points within two months of expiry</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="pb-4">
            Join our <span className="highlight">Bit Byte Rewards </span>Loyalty
            Program today, and let us show our appreciation for your loyalty in
            the most delicious way possible. It's our way of saying 'thank you'
            for choosing us as your go-to source for quality South Indian
            cuisine. We can't wait to reward you with mouthwatering idly and
            dosa batters!
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Loyalty;
