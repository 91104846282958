import React from "react";
import BbfGallery from "../components/modules/BbfGallery";
import Seo from "../components/modules/Seo";
const objSeo = {
  title: "Media Gallery: Exploring our Idly Dosa Batter Journey",
  keywords:
    " Media gallery, Idly Dosa batter, preparation process, product authenticity, finest quality ingredients, local sourcing, true taste, texture, customer testimonials, product images, behind the scenes",
  pathSlug: "/gallery",
  baseUrl: "https://bitbytefoods.com",
  description:
    "Dive into our Media Gallery to explore the visual journey of our meticulous Idly Dosa batter preparation process. Discover the essence of our commitment to product authenticity through images and videos showcasing our use of the finest locally sourced ingredients.",
};

const Gallery = () => {
  return (
    <div>
      <Seo data={objSeo} />
      <BbfGallery />
    </div>
  );
};
export default Gallery;
