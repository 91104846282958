import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/Ragimodule.css";

const Ragimodule = () => {
  return (
    <div>
      <Container className="Ragimodule-container ">
        <h1 className="text-center">
          Our latest addition to the family -{" "}
          <span className="highlight">Ragi Dosa Batter! </span>
        </h1>
        <Row className="Ragimoduleimage-row">
          <Col lg={6} className="Ragimoduleimage-col">
            <img
              src="images/ragibatter1.jpg"
              style={{
                width: "50%",
              }}
              className="ragimoduleimage"
            />
          </Col>
          <Col lg={6} style={{ textAlign: "justify" }}>
            <p>🌿 Packed with the goodness of Ragi!</p>
            <p>🥣 Convenient 500gms packaging!</p>
            <p>💪 Healthy and loaded with all the benefits of Ragi!</p>
            <p>🍽Get ready to elevate your breakfast game! 🌅</p>
            <h6>
              {" "}
              🏃‍♂Hurry, grab yours and kickstart your day with a dose of
              health!
            </h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Ragimodule;
