import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../style/Navigation.css";
const Navigation = () => {
  return (
    <>
      {/* <div className="nav-container"> */}
      <Navbar
        expand="lg"
        bg="dark"
        data-bs-theme="dark"
        sticky="top"
        className="text-light bg-secondary"
      >
        <Container fluid>
          <div className="mobile-view-logo">
            <img alt="" src="images/logo.png" width="120" height="60" />
          </div>
          <Navbar.Brand href="/"></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="nav-items">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/idlydosacamp">Idly Dosa Camp</Nav.Link>
              <Nav.Link href="/rewardsprogram">Rewards Program</Nav.Link>
              <div className="logo">
                <Nav.Link disabled></Nav.Link>
              </div>
              <img
                alt="/bbflogo.png"
                src="images/logo.png"
                width="140"
                height="80"
                className="logo"
              />
              <div className="logo">
                <Nav.Link disabled></Nav.Link>
              </div>
              
              <Nav.Link href="/gallery">Gallery</Nav.Link>
              <Nav.Link href="/franchise">Franchise</Nav.Link>
              <Nav.Link href="/contactUs">Contact Us</Nav.Link>
              <Nav.Link href="/faq">Faq</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* </div> */}
    </>
  );
};

export default Navigation;
