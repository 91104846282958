const LoyaltyData = [
  {
    id: 1,
    imageUrl: "images/loyalty/enrollicon.png",
    text: " To start enjoying the benefits of our Rewards Program, all you need to do is enroll. It's quick, easy, and absolutely free. Just visit our store and let our friendly staff know that you'd like to join the program.",
    imageOnLeft: true,
    stack: "image-on-left",
    leftGrid: 6,
    rightGrid: 6,
    productloyalty: "product-loyalty",
    textimage: "Enroll in the Program",
    textimageloyal: "textimageloyal",
  },
  {
    id: 2,
    imageUrl: "images/loyalty/purchase.png",
    text: " For every purchase of our premium idly or dosa batter, you will receive one point. To qualify, you must buy these products exclusively from our store. Each time you make a purchase, you'll be one step closer to earning your free idly or dosa batter.",
    imageOnLeft: false,
    stack: "image-on-right",
    leftGrid: 6,
    rightGrid: 6,
    productexpire: "products-purchase",
    textimage: "Purchase and enjoy",
    textimageloyal: "textimageloyal",
  },
  {
    id: 3,
    videohtml: (
      <video className="video-loyalty" loop muted autoplay="autoplay">
        <source src="videos/reward.mp4" type="video/mp4" />
      </video>
    ),
    text: " It's as simple as enjoying your favorite batter! Once you've collected 10 points, you'll receive one idly or dosa batter of your choice absolutely free. Whether you're a fan of the classic idly batter, the versatile dosa batter, or both, the choice is entirely yours!",
    imageOnLeft: true,
    stack: "image-on-left",
    leftGrid: 6,
    rightGrid: 6,
    textexpire: "text-expire",
    textimage: "Earn Your Reward",
    textimageloyal: "textimageloyal",
    textimageearn: "textimageearn ",
  },

  {
    id: 4,
    imageUrl: "images/loyalty/expire.png",
    text: " Please note that the rewards points or rewards collected have an expiration date of two months. To enjoy your free idly or dosa batter, make sure to redeem your rewards and make your purchase within two months of earning your 10th point. We want to make sure you enjoy your rewards while they're fresh and delicious!",
    imageOnLeft: false,
    stack: "image-on-right",
    leftGrid: 6,
    rightGrid: 6,
    productspurchaseexpire: "products-purchase-expire ",
    textupdate: "text-update",
    textimage: "Expiration of Rewards",
    textimageloyal: "textimageloyal",
    textimageposition: "textimageposition",
  },
  {
    id: 5,
    imageUrl: "images/loyalty/update.png",
    text: " We understand that staying updated is important, which is why we ensure you're always in the loop. Once you're enrolled in our Loyalty Program, you can expect to receive regular SMS or WhatsApp messages with program updates, special promotions, and exclusive offers. You'll never miss a chance to enjoy great savings and delicious food.",
    imageOnLeft: false,
    stack: "image-on-left",
    leftGrid: 6,
    rightGrid: 6,
    productupdate: "product-update",
    textupdate: "text-update",
    textimage: "Stay Informed",
    textimageloyal: "textimageloyal",
    textimageposition: "textimageposition",
    textimagestay: "textimagestay",
  },
];

export default LoyaltyData;
