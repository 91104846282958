import React from "react";
import "../style/VideoBlock.css";
const VideoBlock = () => {
  return (
    <div className="text-center videoblock">
      <h1 className="videoblock-heading">
        Making <span className="highlight">perfect</span> Idly or Dosa has never
        been this easy.
      </h1>
      <video
        controls
        loop
        muted
        playsinline
        autoPlay="autoplay"
        preload="none"
        className="videoblock-video"
      >
        <source src="videos/videoautoplay.MP4" type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBlock;
