import Carousel from "react-bootstrap/Carousel";
import Heromodule from "./Heromodule";
import Heropage from "./Heropage";
import { useEffect, useState } from "react";

function Herocarosel() {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (window.screen.width > 768) {
      setWidth(window.screen.width);
    }
  }, []);
  return (
    <Carousel>
      <Carousel.Item interval={3000}>
        <Heropage />
      </Carousel.Item>
      {width > 768 && (
        <Carousel.Item interval={3000}>
          <Heromodule />
        </Carousel.Item>
      )}
    </Carousel>
  );
}

export default Herocarosel;
