import React, { useState } from "react";
import "../style/Heromodule.css";

function Heromodule() {
  return (
    <>
      <div className="d-none d-md-block">
        <div
          className="backgroundimg "
          style={{
            backgroundImage: `url("/images/uptable-min.png")`,
            width: "100%",
            height: "88vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          <div className="container-images">
            <div>
              <img src="images/bbf2-min.png" alt="" className="idlidosa" />
            </div>
            <div>
              <img src="images/packmin.png" alt="" className="paddu" />
            </div>

            <div>
              <img src="images/idlivadaa-min.png" alt="" className="idlivada" />
            </div>
            <div>
              <img src="images/all-min.png" alt="" className="all" />
            </div>
            <div>
              <img src="images/steamresize.png" alt="" className="steam" />
            </div>
            <div>
              <img
                src="images/menu-min.png"
                alt=""
                className="menu zoom-in-out"
              />
            </div>
            <div>
              <img
                src="images/hangframe-min.png"
                alt=""
                className="hangingframe"
              />
            </div>
            <div>
              <img src="images/idlivadaa-min.png" alt="" className="all2" />
            </div>
            <div>
              <img src="images/bbf2-min.png" alt="" className="idlidosa2" />
            </div>
          </div>
        </div>

        <div className="textformobile">
          <h1 className="text1">
            <span className="highlight">BIT BYTE FOODS</span>
          </h1>
          <div className="text2">
            <p>
              A Meticulous Journey to Pure Delight. The True Taste of Tradition
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Heromodule;
