import React from "react";
import Loyalty from "../components/modules/Loyalty";
import LoyaltyData from "../components/data/LoyaltyData";
import Seo from "../components/modules/Seo";
const objSeo = {
  title:
    "Bit Byte Rewards Program - Special Rewards for Idly and Dosa Batter Lovers",
  keywords:
    " Bit Byte Foods, loyalty program, rewards, esteemed customers, idly and dosa batter, South Indian delicacies, special offer, Bagalkot, Karnataka",
  pathSlug: "/loyaltyprogram",
  baseUrl: "https://bitbytefoods.com",
  description:
    "At Bit Byte Foods, we deeply value your loyalty and the ongoing support you provide. We're excited to introduce our new rewards program, exclusively crafted for our cherished customers who hold a special place in their hearts for delicious idly and dosa batter.",
};
const LoyaltyProgram = () => {
  return (
    <div>
      <Seo data={objSeo} />
      <Loyalty loyalty={LoyaltyData} />
    </div>
  );
};

export default LoyaltyProgram;
