import React from "react";
import "../style/Menu.css";
import { Container, Row, Col } from "react-bootstrap";

function Menu() {
  return (
    <>
      <Container className="menu-container">
        <Row>
          <Col lg={12}>
            <div className="menucard">
              <video
                className="hero-video"
                autoPlay
                muted
                loop
                type="video/mp4"    
                src="videos/menu.mp4"
              ></video>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Menu;
