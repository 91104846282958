import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { TelephoneForward, At, GeoAlt } from "react-bootstrap-icons";
import "../style/Footer.css";
const Footer = () => {
  return (
    <>
      <Container fluid className="bg-secondary text-light footer-container">
        <Row>
          <Col
            lg={2}
            xs={12}
            // className="text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="images/logo.png" className="bitbyteslogo" />
          </Col>
          <Col lg={4} xs={6}>
            <p className="design">
              <GeoAlt color="royalblue" size={20} />
              BB's Idly Dosa Camp Shop 2, Jain mandir complex Vidayagiri,
              Bagalkote, Karnataka 587102
            </p>
          </Col>
          <Col lg={2} xs={6}>
            <h5>Get in touch</h5>
            <ul
              style={{
                position: "relative",
                right: "30px",
                listStyleType: "none",
              }}
            >
              <li>
                <a
                  href="/idlydosacamp"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Idly Dosa Camp
                </a>
              </li>
              <li>
                <a
                  href="/rewardsprogram"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Rewards Program
                </a>
              </li>
              <li>
                <a
                  href="contactus"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  ContactUs
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Faq
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2} xs={6}>
            <h5>FollowUs</h5>
            <ul
              style={{
                position: "relative",
                right: "30px",
                listStyleType: "none",
              }}
            >
              <li>
                <a
                  href="https://www.facebook.com/bitbytefoods"
                  style={{ textDecoration: "none" }}
                >
                  <FaFacebook /> facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@BitByte-Batter"
                  style={{ textDecoration: "none" }}
                >
                  <FaYoutube /> youtube
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/bitbytefoods/"
                  style={{ textDecoration: "none" }}
                >
                  <FaInstagram /> instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bit-byte-foods/"
                  style={{ textDecoration: "none" }}
                >
                  <FaLinkedinIn /> linkedin
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2} xs={6}>
            {" "}
            <h5>Contact Us</h5>
            <p style={{ margin: 0 }}>
              <TelephoneForward color="royalblue" size={20} />
              6363018056
            </p>
            <p className="contactus" style={{ fontSize: "small", margin: 0 }}>
              <At color="royalblue" size={20} /> bitbytefoods@gmail.com
            </p>
          </Col>
          <Col lg={12}>
            {" "}
            <div className="text-center p-4">
              ©{new Date().getFullYear()} Copyright:
              <a
                href="https://springeveningpl.com/"
                target="_blank"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                All Rights Reserved Site developed by Springevening Private
                Limited
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
