import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/Review.css";

const Review = () => {
  return (
    <section className="team-section py-5">
      <Container>
        <div className="text-center review-heading">
          <h1>Customer Feedback</h1>
        </div>
        <Row className="justify-content-center set review-row">
          <Col sm={12} md={6} lg={6}>
            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
              <div className="card-body p-4">
                <div className="member-profile position-absolute w-100 text-center">
                  <img
                    className="rounded-circle mx-auto d-inline-block shadow-sm"
                    src="/images/review/GeetaGirijaReview.jpg"
                    alt=""
                  />
                </div>
                <div className="card-text pt-1">
                  <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                    Geeta Girija
                  </h5>
                  <div className="mb-3 text-center">
                    Social worker, Bagalkot.
                  </div>
                  <div>
                    Congratulations on the first anniversary. Your good,
                    nutritious and delicious breakfast is appreciated by all. In
                    the modern age when everyone is going to work, the batter
                    you prepare is very good when you want to make quick idli
                    and dosa.May your service continue like this. May your
                    service grow even more.
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
              <div className="card-body p-4">
                <div className="member-profile position-absolute w-100 text-center">
                  <img
                    className="rounded-circle mx-auto d-inline-block shadow-sm"
                    src="/images/review/KoogliSirReview.jpg"
                    alt=""
                  />
                </div>
                <div
                  className="card-text pt-1"
                  style={{ marginBottom: "25px" }}
                >
                  <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                    Prof N.M.Koogali
                  </h5>
                  <div className="mb-3 text-center">
                    {" "}
                    Proud customer of IDC.
                  </div>
                  <div>
                    Another name for breakfast and quality food, is Idly Dosa
                    Camp. Delicious food that meets everyone's needs is
                    available at Idly Dosa Camp. Idli, sambar, chatti, soft
                    vade, crispy dosa satisfy the stomach.
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="card-body text-center">
              <iframe
                className="young-customers"
                loading="lazy"
                src="https://www.youtube.com/embed/NnSD5u4mywo"
                allowFullScreen
              ></iframe>

              <div className="card-footer text-muted  foot margin ">
                <h4 className="person-name">Young Customers</h4>
                <h5 className="place">Namma Bengaluru,Karnataka</h5>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="card-body text-center ">
              <iframe
                loading="lazy"
                className="young-customers"
                src="https://www.youtube.com/embed/Atu4Ff-w3Uw"
                allowFullScreen=""
              ></iframe>

              <div className="card-footer text-muted  foot margin ">
                <h4 className="person-name">JW Vlogs</h4>
                <h5 className="place">Karnataka</h5>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Review;
