import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../style/ThreeCrossTwo.css";
const ThreeCrossTwo = () => {
  return (
    <div>
      <Container className="threecrosstwo-container">
        <h1 className="text-center pb-3">
          Discover the <span className="highlight">Essence</span> of South India
          at BBF's Idly Dosa Camp
        </h1>
        <Row>
          <Col lg={6} className="threecrosstwo-col-left">
            <div className="familypic left-div">
              {" "}
              <img src="images/familypic.png" style={{ width: "100%" }} />
            </div>
          </Col>
          <Col lg={6} className="threecrosstwo-col-right bg-primary">
            <div className="right-div">
              {" "}
              <p className="flavorful " style={{ textAlign: "justify" }}>
                Step into the flavorful world of Idly Dosa Camp🌟,where our menu
                is a culinary playground🍽️.While we've got a tempting array of
                dishes, our real stars are the iconic idly and dosa – South
                Indian delights that'll turn your cravings into an epic food
                journey🥘🌶️.Come and experience the true essence of South Indian
                cuisine with us.🤩🧑‍🍳
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ padding: 0 }}>
          <Col lg={6} className="threecrosstwo-col-left ">
            <div className="left-div bg-dark text-center">
              <img
                src="images/idcdosa.png"
                className="threecrosstwo-idlivadadosa"
              />
            </div>
          </Col>
          <Col lg={6} className="threecrosstwo-col-right bg-secondary">
            <div className="right-div">
              <blockquote class="blockquote">
                <p className="threecrosstwo-para highlight ">
                  "Food may be essential as fuel for the body, but good food is
                  fuel for the soul"
                </p>
                <footer
                  class="blockquote-footer"
                  style={{ textAlign: "right" }}
                >
                  <cite title="Source Title"> Malcolm Forbes</cite>
                </footer>
              </blockquote>
            </div>
          </Col>
        </Row>{" "}
        <Row>
          <Col lg={6} className="threecrosstwo-col-left">
            <div className="left-div">
              <img
                src="images/gallery/bbcamp1.JPG"
                className="bbcamp-image"
              />
            </div>
          </Col>
          <Col lg={6} className="threecrosstwo-col-right">
            <div className="right-div">
              <img src="images/idc.png" className="idc-image" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ThreeCrossTwo;
